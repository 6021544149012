export default {
  translation: {
    'shared.french': 'Français',
    'app.name': 'Espace Partenaire Hellio',
    'auth.hero': 'Bienvenue sur votre\nEspace Partenaire',
  },
  global: {
    password: 'Mot de passe',
    email: 'E-mail',
    phone: 'Téléphone',
    logout: 'Déconnexion',
    cancel: 'Annuler',
    required: 'Ce champ est obligatoire',
    internet_connexion_error:
      "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
    'email.required': 'Veuillez renseigner une adresse e-mail valide',
    session_expired: 'Votre session a expiré. Veuillez vous reconnecter.',
    previews: 'Précédents',
    next: 'Suivant',
    yes: 'Oui',
    no: 'Non',
    date: 'Date',
    mr: 'Monsieur',
    mrs: 'Madame',
  },
  login: {
    hero: 'Connexion',
    description: 'Renseignez votre adresse email et votre mot de passe',
    forgot: 'Mot de passe oublié',
    login_error: 'Votre identifiant ou votre mot de passe est incorrect',
    submit: 'Se connecter',
  },
  first_login: {
    hero: 'Première connexion',
    description: 'Renseignez votre mot de passe',
    success: 'Création de votre mot de passe',
    newPassword: 'Nouveau mot de passe*',
    error: "Votre lien à expiré ou n'est plus valide",
    success_description:
      'La création de votre mot de passe a bien été prise en compte.',
    success_action: 'Me connecter',
    submit: 'Valider le mot de passe',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmer mot de passe*',
    'confirm.regex.error':
      'Le mot de passe doit contenir 8 caractères : une majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  change_password: {
    hero: 'Changement de mot de passe',
    newPassword: 'Nouveau mot de passe*',
    description: 'Renseignez votre nouveau mot de passe',
    submit: 'Valider le mot de passe',
    success: 'Modification du mot de passe',
    success_description:
      'La modification de votre mot de passe a bien été effectuée.',
    success_action: 'Me connecter',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmer le nouveau mot de passe*',
    'confirm.regex.error':
      'Le mot de passe doit contenir 8 caractères : une majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  404: {
    hero: 'Page introuvable',
    description: "La page que vous cherchez n'existe pas ou a été déplacée.",
    back: "Retour à l'accueil",
  },
  not_implemented: {
    hero: 'Non implémenté',
  },
  reset_password: {
    hero: 'Mot de passe oublié',
    description:
      'Veuillez saisir votre email de connexion afin de recevoir le lien de réinitialisation de votre mot de passe.',
    submit: 'Valider',
    error: "Votre lien à expiré ou n'est plus valide",
    back: 'Retour à la connexion',
    success: 'Réinitialisation du mot de passe',
    success_title: 'Réinitialisation du mot de passe',
    success_description:
      "Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe. Pour créer votre nouveau mot de passe, il vous suffit de cliquer sur le lien contenu dans l'e-mail et d'en saisir un nouveau.<br>" +
      "<strong>Vous n'avez pas reçu cet e-mail</strong><br> Vérifiez vos courriers indésirables ou contactez : ",
    email: 'plateforme-partenaires@hellio.com',
  },
  homepage: {
    hero: 'Accueil',
  },
  contact: {
    title: 'Votre référent Hellio',
    no_contact: 'Aucun référent commercial n’est rattaché à votre compte.',
  },
  calculer_une_prime: {
    hero: 'Calculer une prime',
    description:
      'Calculez la prime CEE Hellio associée à votre opération ' +
      'en quelques clics.',
    champs_requis: {
      champs: "Les champs accompagnés d'un ",
      requis: ' sont requis.',
    },
    operation: "Sélectionnez l'opération correspondant à votre demande",
    operation_date: "Date prévisionnelle de l'opération ",
    entrez_code_postal: 'Entrez un code postal',
    batiment: {
      question: "De quel type de bâtiment s'agit-il ? ",
      agriccole: 'Bâtiment agricole',
      industriel: 'Bâtiment industriel',
      tertiaire: 'Bâtiment tertiaire',
      residentiel: 'Bâtiment résidentiel',
    },
    criteres_eligibilite: "Critères d'éligibilité",
    calculer_ma_prime: 'Calculer ma prime ',
    resultat: {
      votre_situation: 'Votre situation',
      reinitialiser: 'Réinitialiser',
      type_de_batiment: 'Type de bâtiment',
      mode_de_fonctionnement_du_site: 'Mode de fonctionnement du site',
      type_operation: "Type d'opération (FOST)",
      puissance_nominale_du_moteur: 'Puissance nominale du moteur (en Kw',
      code_postal: 'Code postal',
      montant_de_la_prime: 'Montant de la prime',
      montant_de_la_prime_description:
        'Le montant de prime estimé indiqué ici est calculé sur la base des paramètres que vous avez indiqué dans le calcul. Ce montant est conditionné au respect des critères d’éligibilité CEE et est susceptible d’évoluer en fonction des travaux effectivement réalisés et de l’évolution des paramètres déclarés à ce stade',
      volume_de_CEE_reel: 'Volume de CEE réel',
      prime_globale: 'Prime globale',
      prime_pro: 'Prime professionnel',
      prime_beneficiaire: 'Prime bénéficiaire',
      prime_professionnel: 'Prime professionnel',
      repartition_beneficiaire_pro: 'Répartition bénéficiaire / professionnel',
      montant_max_prime:
        'Le montant maximum de la Prime Professionnel ne peut dépasser 50%',
      montant_prime_rappel_warning:
        'Nous vous rappelons de faire valider' +
        ' votre devis' +
        ' avec remise par nos équipes spécialisées avant de le faire signer à vos clients.',
      enregistrer_ma_simulation: 'Enregistrer ma simulation',
      precedent: 'Précédent',
      soumettre_un_dossier: 'Soumettre un dossier',
      enregistrer_une_simulation: 'Enregistrer une simulation',
      nom_de_la_simulation: 'Nom de la simulation',
      transformer_simulation_dossier: 'Voulez-vous transformer votre simulation en dossier ?',
      completer_informations_chantier: ' Vous serez invité à compléter les informations du chantier.',
      oui_je_completer_informations: 'Oui, je complète les informations',
      non_je_nomme_simulation: 'Non, je nomme ma simulation',
      veuillez_nommer_simulation: 'Veuillez nommer votre simulation',
      nom_obligatoire: 'Le nom de la simulation est obligatoire',
      nom_placeholder: 'Nom de la simulation',
      valider: 'Valider',
      confirmation_enregistrement: {
        sauvegarde_de_simulation: 'Sauvegarde de la simulation',
        simulation_bien_enregistree: 'Votre simulation a bien été enregistrée',
        simulation_probleme_enregistrement: 'Un problème est survenu lors de l’enregistrement de votre simulation',
        acceder_historique_simulation: "Accéder à l'historique des simulations",
      },
      error: {
        montant_prime_non_calculable:
          "Le montant de la prime n'est pas calculable en l'état.",
        information_referent:
          'Votre référent Hellio a été informé et va' +
          ' apporter les corrections nécessaires.',
      },
      revenir_a_la_calculette: 'Revenir à la calculette',
    },
  },
  soumettre_un_dossier: {
    etapes: {
      etape_1: 'Informations chantier',
      etape_2: 'Informations site',
      etape_3: 'Informations bénéficiaire',
      etape_4: 'Informations complémentaires',
    },
  },
};
